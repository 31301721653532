import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AuthProvider } from './components/general/AuthContext';
import Staff from './components/staff/Staff';
import EmployeeDetails from './components/staff/EmployeeDetails';
import BargainingUnits from './components/units/BargainingUnits';
import BargainingUnitInfo from './components/units/BargainingUnitInfo';
import Towns from './components/towns/Towns';
import Town from './components/towns/Town';
import UnassignedUnits from './components/units/ManageLocals';
import ScrollToTop from './components/general/ScrollToTop';
import Metrics from './components/metrics/Metrics';
import AuditLog from './components/audit/AuditLog';
import Login from './components/general/Login.js';
import { initGA, logPageView } from './analytics';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    initGA();
    logPageView();
  }, []);

  useEffect(() => {
    logPageView();
  }, [location]);

  return (
    <>
      <ScrollToTop />
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Metrics />} />
          <Route path="/staff" element={<Staff />} />
          <Route path="/employee/:id" element={<EmployeeDetails />} />
          <Route path="/units/" element={<BargainingUnits />} />
          <Route path="/unit-info/:id" element={<BargainingUnitInfo />} />
          <Route path="/towns/" element={<Towns />} />
          <Route path="/town/:town" element={<Town />} />
          <Route path="/unassigned-units" element={<UnassignedUnits />} />
          <Route path="/metrics" element={<Metrics />} />
          <Route path="/audit-log" element={<AuditLog />} />
          <Route path="/login" element={<Login />} />
          <Route path="/units/:id" element={<BargainingUnitInfo />} />
        </Routes>
      </AuthProvider>
    </>
  );
};

export default App;
