import React, { useState, useEffect } from "react";
import NavBar from "./../navigation/Nav";
import { Link } from 'react-router-dom';



const nameMapping = {
  RepAssigned1: "Staff Rep 1",
  RepAssigned2: "Staff Rep 2",
  RepAssigned1_Id: "Representative 1 ID",
  RepAssigned2_Id: "Representative 2 ID",
  ServiceFieldRep1: "Service Rep 1",
  ServiceFieldRep1_Id: "Service Rep 1 ID",
  ServiceFieldRep2: "Service Rep 2",
  ServiceFieldRep2_Id: "Service Rep 2 ID",
  Organizer: "Organizer",
  Organizer_Id: "Organizer ID",
  TOWN: "Town",
  CBA_START: "CBA Start Date",
  CBA_EXP: "CBA Expiry Date",
  PayrollHRContactName: "Payroll HR Contact",
  ActiveJobCount: "Active Job Count",
  TotalDuesPayers: "Total Dues Payers",
  OnCentralizedDues: "On Centralized Dues",
  NumberOfHomeEmails: "Home Emails Count",
  NumberOfPhoneNumbers: "Phone Numbers Count",
  DateLastBargUnitListReceived: "Last Bargaining Unit List Date",
  AuditCompleteOverview: "Audit Completion Date",
  PEOPLEContributors: "PEOPLE Contributors",
  TotalOPTOUTS: "Total OPT-OUTS",
  RecommitsSaves: "Recommits Saves",
  DateLastOfficerUpdate: "Last Officer Update Date",
  LastElection: "Last Election Date",
  IUMonthYearNextElection: "Next Election Date",
  LengthOfTerm: "Length of Term",
  PresidentContactNumber: "President's Contact Number",
  bu_STAFF_ASSIGNED: "Staff Assigned",
  bu_STAFF_ASSIGNED_ID: "Staff Assigned ID",
  PrimaryKey: "Bargaining Unit ID",
  VicePresidentFirstName: "Vice President First Name",
  VicePresidentLastName: "Vice President Last Name",
  PresidentFirstName: "President First Name",
  PresidentLastName: "President Last Name",
  TreasurerFirstName: "Treasurer First Name",
  TreasurerLastName: "Treasurer Last Name",
  RecordingSecretaryFirstName: "Secretary First Name",
  RecordingSecretaryLastName: "Secretary Last Name",
  FieldRep1: "Field Rep",
};
const AuditLog = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchLogs();
  }, []);

  const fetchLogs = async () => {
    setLoading(true);
    try {
      const response = await fetch("/api/audit-logs"); 
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setLogs(data.map((log) => ({
        ...log,
        Differences: log.Differences ? JSON.parse(log.Differences) : {},
      })));
    } catch (error) {
      console.error("Error fetching audit logs:", error);
      setError("Failed to fetch data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const formatChangeDetails = (differences) => {
    return Object.entries(differences)
      .filter(([key, obj]) => key !== 'id' && !key.endsWith("_Id"))  // Exclude 'id' and fields ending with "_Id"
      .map(([key, obj]) => {
        const friendlyKey = nameMapping[key] || key;
        const oldVal = obj.old || "Nothing";
        const newVal = obj.new || "No changes detected.";
  
        return (
          <div key={key}>
            {`${friendlyKey} was changed from `} 
            <strong>{oldVal}</strong> 
            {` to `} 
            <strong>{newVal}</strong>
          </div>
        );
      });
  };
  
  
  

  return (
    <>
      <NavBar />
      <div style={{ padding: "20px" }}>
        <h1>Audit Logs</h1>
        {loading && <p>Loading...</p>}
        {error && <p style={{ color: "red" }}>{error}</p>}
        <ul>
          {logs.map((log) => (
            <li key={log.LogID} style={{ marginBottom: "10px", borderBottom: "1px solid #ccc", paddingBottom: "10px" }}>
              <strong>Operation:</strong> {log.OperationType}
              <br />
              <br />
              <strong>Differences:</strong>
              {formatChangeDetails(log.Differences)}
              {log.PrimaryKey && (
                <p>
                  <strong>Bargaining Unit Affected:</strong> 
                  <Link to={`/unit-info/${log.PrimaryKey}`}>View Unit</Link>
                </p>
              )}
              <strong>Timestamp:</strong> {new Date(log.ChangeTimestamp).toLocaleString()}
              <br />
              <strong>Changed By:</strong> {log.ChangedBy}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
  
  
};

export default AuditLog;
