import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Nav from "./../navigation/Nav";
import { useParams } from "react-router-dom";
import MapComponent from "./../maps/MapComponent";  // Import the MapComponent

function Town() {
  const [townList, setTownList] = useState([]);
  const [townCoordinates, setTownCoordinates] = useState(null);  // Store town's coordinates
  const { town } = useParams();

  useEffect(() => {
    // Fetch town-specific GeoJSON data
    fetch('/CT_Municipalities.geojson')  // Fetch GeoJSON data
      .then((response) => response.json())
      .then((data) => {
        const townFeature = data.features.find(
          (feature) => feature.properties.Municipality.toLowerCase() === town.toLowerCase()
        );

        // Extract town coordinates (centroid or rough center of the polygon)
        if (townFeature) {
          const coordinates = townFeature.geometry.coordinates[0];  // First ring of the polygon
          const townCenter = calculatePolygonCenter(coordinates);   // Calculate center of the polygon
          setTownCoordinates(townCenter);
        } else {
          console.error("Town not found in GeoJSON data");
        }
      })
      .catch((error) => console.error("Error fetching GeoJSON:", error));

    // Fetch additional town-specific data if needed
    fetch(`/api/town/${town}`)
      .then((response) => response.json())
      .then((data) => setTownList(data))
      .catch((error) => console.error("Error fetching town info:", error));
  }, [town]);

  // Function to calculate the center of a polygon (averaging coordinates)
  const calculatePolygonCenter = (coordinates) => {
    let latSum = 0;
    let lonSum = 0;
    let pointCount = 0;

    // Loop through all points in the polygon's first ring
    coordinates.forEach(point => {
      const [lon, lat] = point;  // GeoJSON format is [longitude, latitude]
      latSum += lat;
      lonSum += lon;
      pointCount++;
    });

    const centerLat = latSum / pointCount;
    const centerLon = lonSum / pointCount;
    return [centerLat, centerLon];
  };

  return (
    <div style={{ marginBottom: "100px" }}>
      <Nav />
      
      {/* Pass town coordinates to MapComponent */}
      {townCoordinates ? (
        <MapComponent center={townCoordinates} zoom={12} />
      ) : (
        <div>Loading map...</div>  // Show a loading message while fetching coordinates
      )}

      <h2
        style={{
          fontSize: "40px",
          margin: "0 auto", 
          padding: "20px", 
          flex: "1", 
          width: "50%",
          textAlign: "center"
        }}
      >
        All Units in {town}
      </h2>
      
      <div
        style={{
          width: "800px",
          margin: "0 auto",
          marginTop: "50px",
          marginBottom: "100px"
        }}
      >
        {townList.map((townItem, index) => (
          <div
            className="townSelector"
            style={{
              fontSize: "18px",
              padding: "5px",
              cursor: "grab",
              borderRadius: "4px",
              marginBottom: "20px",
            }}
            key={index}
          >
            <Link to={`/unit-info/${townItem.id}`}>
              <span style={{color:'#944d4d'}}>
                {townItem.LOCAL} - {townItem.UNIT.toString().padStart(4, "0")}
              </span> &nbsp;
              {townItem.NAME}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Town;
