import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Nav from './../navigation/Nav';
import './../styles/towns/Towns.css';
import MapComponent from './../maps/MapComponent.js';  // Import the MapComponent

function Towns() {
    const [groupedTowns, setGroupedTowns] = useState({});

    useEffect(() => {
        fetch(`/api/towns`)
            .then(response => response.json())
            .then(data => {
                const sortedTowns = data.sort((a, b) => a.Name.localeCompare(b.Name));
                const grouped = sortedTowns.reduce((acc, town) => {
                    const firstLetter = town.Name.charAt(0).toUpperCase();
                    if (!acc[firstLetter]) {
                        acc[firstLetter] = [];
                    }
                    acc[firstLetter].push(town);
                    return acc;
                }, {});
                setGroupedTowns(grouped);
            })
            .catch(error => console.error('Error fetching town info:', error));
    }, []);

    const letters = Object.keys(groupedTowns);

    return (
        <div style={{ marginBottom: '100px' }}>
            <Nav />
            
            {/* Use the MapComponent without passing geoJsonData */}
            <MapComponent /> 

            <div style={{ fontSize: '40px', textAlign: 'center', fontWeight: 'bolder', marginTop: '40px', marginBottom: '50px' }}>
                All Towns
            </div>

            {/* Alphabet navigation bar */}
            <div className="alphabet-nav" style={{ textAlign: 'center', marginBottom: '20px' }}>
                {letters.map(letter => (
                    <a
                        key={letter}
                        href={`#${letter}`}
                        className="alphabet-link"
                    >
                        {letter}
                    </a>
                ))}
            </div>

            <div style={{ width: '600px', margin: '0 auto', marginTop: '100px' }}>
                {Object.entries(groupedTowns).map(([letter, towns]) => (
                    <div key={letter} id={letter}>
                        <h1>{letter}</h1>
                        {towns.map((townItem, index) => (
                            <div
                                className="townSelector"
                                style={{
                                    fontSize: '20px',
                                    padding: '5px',
                                    cursor: 'grab',
                                    borderRadius: '4px',
                                    marginBottom: '10px',
                                }}
                                key={townItem.id || index}
                            >
                                <Link to={`/town/${townItem.Name}`}>{townItem.Name}</Link>
                                <span style={{ float: 'right' }}>{townItem.TownCount}</span>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Towns;
