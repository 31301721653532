import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Use named import

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({ token: null, isAuthenticated: false, user: null });
    const navigate = useNavigate();

    useEffect(() => {
        const token = sessionStorage.getItem('token');
        if (token) {
            try {
                const decodedUser = jwtDecode(token); // Decode the token to get user info
                setAuth({ token, isAuthenticated: true, user: decodedUser });
            } catch (error) {
                console.error("Error decoding token:", error);
                setAuth({ token: null, isAuthenticated: false, user: null });
                sessionStorage.removeItem('token'); // Clear invalid token
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
    }, [navigate]);

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
