import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './../styles/staff/Staff.css';
import './../styles/general/Reset.css';
import Nav from './../navigation/Nav';

function UnassignedUnits() {
	const [unassignedUnitList, setUnassignedUnitList] = useState([]);
	const [employees, setEmployees] = useState([]);
	const [staffReps, setStaffReps] = useState([]);
	const [Organizers, setOrganizers] = useState([]);
	const [serviceReps, setServiceReps] = useState([]);
	const [FieldReps, setFieldReps] = useState([]);
	const [selectedEmployees, setSelectedEmployees] = useState({});
	const [sortConfig, setSortConfig] = useState({
		key: null,
		direction: 'ascending',
	});

	const handleEmployeeChange = (unitId, employeeNumber, event) => {
		setSelectedEmployees(prevState => ({
			...prevState,
			[unitId]: {
				...prevState[unitId],
				[`employee${employeeNumber}`]: event.target.value,
			},
		}));
	};

	const handleSubmit = unitId => {
		const selectedEmployeesForUnit = selectedEmployees[unitId];
		const employee1Id = String(selectedEmployeesForUnit.employee1);
		const employee2Id = String(selectedEmployeesForUnit.employee2);
		const employee3Id = String(selectedEmployeesForUnit.employee3);
		const employee4Id = String(selectedEmployeesForUnit.employee4);
		const employee5Id = String(selectedEmployeesForUnit.employee5);
		const employee1Name = employees.find(emp => String(emp.EmployeeId) === employee1Id)?.full_name || '';
		const employee2Name = employees.find(emp => String(emp.EmployeeId) === employee2Id)?.full_name || '';
		const employee3Name = serviceReps.find(emp => String(emp.EmployeeId) === employee3Id)?.full_name || '';
		const employee4Name = FieldReps.find(emp => String(emp.EmployeeId) === employee4Id)?.full_name || '';
		const employee5Name = Organizers.find(emp => String(emp.EmployeeId) === employee5Id)?.full_name || '';
		console.log('Selected Employees for Unit ->', selectedEmployeesForUnit);
		const submission = {
			...selectedEmployeesForUnit,
			employee1Name,
			employee2Name,
			employee3Name,
			employee4Name,
			employee5Name,
		};
		console.log('Submission ->', submission);
		fetch(`/api/assign-employees/${unitId}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(submission),
		})
			.then(response => {
				if (response.ok) {
					alert('Employees assigned successfully');
				} else {
					alert('Error assigning employees' + response.statusText);
					console.log(response);
				}
			})
			.catch(error => {
				console.error('Error assigning employees: ', error);
				alert('Error assigning employees');
			});
	};

	useEffect(() => {
		fetch('/api/staff-reps')
			.then(response => response.json())
			.then(data => setEmployees(data));
	}, []);

	useEffect(() => {
		fetch('/api/service-reps')
			.then(response => response.json())
			.then(data => setServiceReps(data));
	}, []);

	useEffect(() => {
		fetch('/api/organizers')
			.then(response => response.json())
			.then(data => setOrganizers(data));
	}, []);

	useEffect(() => {
		fetch('/api/unassigned-units')
			.then(response => response.json())
			.then(data => setUnassignedUnitList(data))
			.catch(error => console.error('Error fetching Unassigned Unit Data: ', error));
	}, []);

	useEffect(() => {
		fetch('/api/field-reps')
			.then(response => response.json())
			.then(data => setFieldReps(data));
	}, []);

	const sortData = key => {
		let direction = 'ascending';
		if (sortConfig.key === key && sortConfig.direction === 'ascending') {
			direction = 'descending';
		}
		setSortConfig({ key, direction });

		setUnassignedUnitList(unassignedUnitList =>
			[...unassignedUnitList].sort((a, b) => {
				if (a[key] < b[key]) {
					return direction === 'ascending' ? -1 : 1;
				}
				if (a[key] > b[key]) {
					return direction === 'ascending' ? 1 : -1;
				}
				return 0;
			})
		);
	};

	return (
		<div>
			<Nav />
			<header>
				<div
					style={{
						fontSize: '40px',
						textAlign: 'center',
						fontWeight: 'bolder',
						marginTop: '40px',
						marginBottom: '50px',
					}}
				>
					Unassigned Bargaining Units
				</div>
			</header>
			<main>
				<section>
					<div className="tableContainer">
						<table>
							<thead>
								<tr style={{ backgroundColor: '#f2f2f2' }}>
									{['LOCAL', 'UNIT', 'NAME', 'TOWN'].map(column => (
										<th
											key={column}
											style={{
												padding: '2px',
												textAlign: 'left',
												borderBottom: '1px solid #ddd',
												cursor: 'pointer',
											}}
											onClick={() => sortData(column)}
										>
											{column}
										</th>
									))}
									<th
										style={{
											padding: '2px',
											textAlign: 'left',
											borderBottom: '1px solid #ddd',
										}}
									>
										Staff Rep
									</th>
									<th
										style={{
											padding: '2px',
											textAlign: 'left',
											borderBottom: '1px solid #ddd',
										}}
									>
										Service Rep
									</th>
									
									<th
										style={{
											padding: '2px',
											textAlign: 'left',
											borderBottom: '1px solid #ddd',
										}}
									>
										Field Rep
									</th>
									
									<th
										style={{
											padding: '2px',
											textAlign: 'left',
											borderBottom: '1px solid #ddd',
										}}
									>
										Organizer
									</th>
									<th
										style={{
											padding: '2px',
											textAlign: 'left',
											borderBottom: '1px solid #ddd',
										}}
									>
										Staff Rep 2
									</th>
									<th
										style={{
											padding: '2px',
											textAlign: 'left',
											borderBottom: '1px solid #ddd',
										}}
									>
										Assign
									</th>
								</tr>
							</thead>
							<tbody>
								{unassignedUnitList.map(unit => (
									<tr key={unit.id}>
										<td
											style={{
												padding: '2px',
												borderBottom: '1px solid #ddd',
												fontSize: '14px',
											}}
										>
											{unit.LOCAL}
										</td>
										<td
											style={{
												padding: '2px',
												textAlign: 'left',
												borderBottom: '1px solid #ddd',
												fontSize: '14px',
											}}
										>
											{unit.UNIT.toString().padStart(4, '0')}
										</td>
										<td
											style={{
												padding: '2px',
												borderBottom: '1px solid #ddd',
												fontSize: '14px',
											}}
										>
											<Link to={`/unit-info/${unit.id}`}>{unit.NAME}</Link>
										</td>
										<td
											style={{
												padding: '2px',
												borderBottom: '1px solid #ddd',
												fontSize: '14px',
											}}
										>
											{unit.TOWN}
										</td>
										<td
											style={{
												padding: '2px',
												borderBottom: '1px solid #ddd',
												fontSize: '14px',
											}}
										>
											<select value={selectedEmployees[unit.id]?.employee1 || ''} onChange={event => handleEmployeeChange(unit.id, 1, event)}>
												<option value={null}>--</option>
												{employees.map(employee => (
													<option key={employee.EmployeeId} value={employee.EmployeeId}>
														{employee.full_name}
													</option>
												))}
											</select>
										</td>
										
										<td
											style={{
												padding: '2px',
												borderBottom: '1px solid #ddd',
												fontSize: '14px',
											}}
										>
											<select value={selectedEmployees[unit.id]?.employee3 || ''} onChange={event => handleEmployeeChange(unit.id, 3, event)}>
												<option value={null}>--</option>
												{serviceReps.map(serviceRep => (
													<option key={serviceRep.EmployeeId} value={serviceRep.EmployeeId}>
														{serviceRep.full_name}
													</option>
												))}
											</select>
										</td>
										
												<td
													style={{
														padding: '2px',
														borderBottom: '1px solid #ddd',
														fontSize: '14px',
													}}
												>
													<select value={selectedEmployees[unit.id]?.employee4 || ''} onChange={event => handleEmployeeChange(unit.id, 4, event)}>
														<option value={null}>--</option>
														{FieldReps.map(FieldReps => (
															<option key={FieldReps.EmployeeId} value={FieldReps.EmployeeId}>
																{FieldReps.full_name}
															</option>
														))}
													</select>
												</td>
										<td
											style={{
												padding: '8px',
												borderBottom: '1px solid #ddd',
												fontSize: '14px',
											}}
										>
											<select value={selectedEmployees[unit.id]?.employee5 || ''} onChange={event => handleEmployeeChange(unit.id, 5, event)}>
												<option value={null}>--</option>
												{Organizers.map(organizers => (
													<option key={organizers.EmployeeId} value={organizers.EmployeeId}>
														{organizers.full_name}
													</option>
												))}
											</select>
										</td>
										<td
											style={{
												padding: '2px',
												borderBottom: '1px solid #ddd',
												fontSize: '14px',
											}}
										>
											<select value={selectedEmployees[unit.id]?.employee2 || ''} onChange={event => handleEmployeeChange(unit.id, 2, event)}>
												<option value={null}>--</option>
												{employees.map(employee => (
													<option key={employee.EmployeeId} value={employee.EmployeeId}>
														{employee.full_name}
													</option>
												))}
											</select>
										</td>
										<td>
											<button onClick={() => handleSubmit(unit.id)}>Submit</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</section>
			</main>
		</div>
	);
}

export default UnassignedUnits;
