import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './../styles/staff/Staff.css';
import './../styles/general/Reset.css';
import NavBar from './../navigation/Nav';

function Staff() {
	const [staffReps, setStaffReps] = useState([]);
	const [serviceReps, setServiceReps] = useState([]);
	const [fieldReps, setFieldReps] = useState([]);
	const [organizers, setOrganizers] = useState([]);

	useEffect(() => {
		const endpoints = ['/api/staff-reps', '/api/service-reps', '/api/field-reps', '/api/organizers'];
		const stateSetters = [setStaffReps, setServiceReps, setFieldReps, setOrganizers];

		endpoints.forEach((endpoint, index) => {
			fetch(endpoint)
				.then(response => response.json())
				.then(data => stateSetters[index](data))
				.catch(error => console.error(`Error fetching data from ${endpoint}:`, error));
		});
	}, []);

	const renderTable = (data, title) => (
		<div style={{ flex: 1, minWidth: '150px', margin: '10px', marginBottom:'100px' }}>
			<h2 style={{ textAlign: 'center' }}>{title}</h2>
			<table style={{ width: '100%', borderCollapse: 'collapse' }}>
				<thead>
					<tr style={{ backgroundColor: '#f2f2f2' }}>
						<th
							style={{
								padding: '10px',
								textAlign: 'left',
								borderBottom: '1px solid #ddd',
								fontSize: '16px',
							}}
						></th>
					</tr>
				</thead>
				<tbody>
					{data.map((staff, index) => (
						<tr key={index}>
							<td
								style={{
									padding: '8px',
									textAlign: 'left',
									borderBottom: '1px solid #ddd',
									fontSize: '18px',
								}}
							>
								<Link to={`/employee/${staff.EmployeeId}`}>{staff.full_name}</Link>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);

	return (
		<div>
			<NavBar />
			<header>
				<div style={{ textAlign: 'center', padding: '40px' }}>
					<div style={{ fontSize: '40px', textAlign: 'center', fontWeight: 'bolder', marginTop: '40px', marginBottom: '50px' }}>Employee Directory</div>
				</div>
			</header>
			<main>
				<div className="staffTables">
					{renderTable(staffReps, 'Staff Reps')}
					{renderTable(serviceReps, 'Service Reps')}
					{renderTable(fieldReps, 'Field Reps')}
					{renderTable(organizers, 'Organizers')}
				</div>
			</main>
		</div>
	);
}

export default Staff;
