import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './../styles/staff/Staff.css';
import './../styles/general/Reset.css';
import Nav from './../navigation/Nav';

function BargainingUnits() {
    const [bargainingUnitList, setBargainingUnitList] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

    useEffect(() => {
        fetch('/api/units')
            .then(response => response.json())
            .then(data => setBargainingUnitList(data))
            .catch(error => console.error('Error fetching Bargaining Unit Data: ', error));
    }, []);

    const sortData = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        setBargainingUnitList(bargainingUnitList => [...bargainingUnitList].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        }));
    };

    return (
        <div>
            <Nav />
            <header>
                <div style={{ textAlign: 'center', padding: '0px 0px 0px 40px' }}>
                    <div style={{ fontSize: '40px', textAlign: 'center', fontWeight: 'bolder', marginTop: '40px', marginBottom: '50px' }}>Bargaining Units</div>
                </div>
            </header>
            <main>
                <section>
                    <table
                        style={{
                            width: '70%',
                            borderCollapse: 'collapse',
                            margin: '0 auto',
                        }}
                    >
                        <thead>
                            <tr style={{ backgroundColor: '#f2f2f2' }}>
                                {['LOCAL', 'UNIT', 'NAME', 'TOWN', 'REP ASSIGNED'].map(column => (
                                    <th
                                        key={column}
                                        style={{
                                            padding: '8px',
                                            textAlign: 'left',
                                            borderBottom: '1px solid #ddd',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => sortData(column)}
                                    >
                                        {column.replace('_', ' ')}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {bargainingUnitList.map(unit => (
                                <tr key={unit.id}>
                                    <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd', fontSize: '14px' }}>{unit.LOCAL}</td>
                                    <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd', fontSize: '14px' }}>
                                        {unit.UNIT.toString().padStart(4, '0')}
                                    </td>
                                    <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd', fontSize: '14px' }}><Link to={`/unit-info/${unit.id}`}>{unit.NAME}</Link></td>
                                    <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd', fontSize: '14px' }}><Link to={`/town/${unit.TOWN}`}>{unit.TOWN}</Link></td>
                                    <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd', fontSize: '14px' }}><Link to={`/employee/${unit.STAFF_ASSIGNED_ID}`}>{unit.STAFF_ASSIGNED}</Link></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </section>
            </main>
        </div>
    );
}

export default BargainingUnits;
