import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Nav from './../navigation/Nav';

function EmployeeDetails() {
    const { id } = useParams(); // Get the employee ID from the URL
    const [employeeDetails, setEmployeeDetails] = useState(null);

    useEffect(() => {
        fetch(`/api/employee/${id}`)
            .then(response => response.json())
            .then(data => {
                console.log('Employee Details:', data);
                setEmployeeDetails(data); // Assuming data includes all necessary information
            })
            .catch(error => console.error('Error fetching employee details:', error));
    }, [id]);

    // Handling the loading state
    if (!employeeDetails) {
        return (
            <>
                <Nav />
                <div style={{ fontSize: '30px', margin: '0 auto', width: '80%', textAlign: 'center', marginTop: '150px', fontWeight: 'bold' }}>
                    No info for the employee with ID: {id}. Report to Webmaster jonny@two-bit-operation.com.
                </div>
            </>
        );
    }

    // Display message if employee does not have any units under them
    if (employeeDetails.length === 0) {
        return (
            <>
                <Nav />
                <div style={{ fontSize: '30px', margin: '0 auto', width: '80%', textAlign: 'center', marginTop: '150px', fontWeight: 'bold' }}>
                    Employee does not have any Units under them. <Link to={'/staff/'}>Go back to Staff page</Link>
                </div>
            </>
        );
    }

    return (
        <div>
            <Nav />
            <div className="employeeInformation">
                {/* Display employee's full name only once */}
                <h1 style={{ padding: '20px', fontSize: '40px', marginLeft: '50px' }}>
                    {employeeDetails[0] && employeeDetails[0].employee_full_name}
                </h1>
            </div>
            <table style={{ width: '70%', borderCollapse: 'collapse', margin: '0 auto', fontSize: '16px', marginTop: '60px' }}>
                <thead>
                    <tr style={{ backgroundColor: '#f2f2f2', padding: '10px', textAlign: 'left' }}>
                        <th>Local</th>
                        <th>Chapter</th>
                        <th>Bargaining Unit</th>
                        <th>Town</th>
                    </tr>
                </thead>
                <tbody>
                    {employeeDetails.map((row, index) => (
                        <tr key={index}>
                            <td>{row.UnitNumber}</td>
                            <td>{row.UnitChapter !== null && row.UnitChapter !== undefined ? row.UnitChapter.toString().padStart(4, "0") : "----"}</td>
                            <td><Link to={`/unit-info/${row.ID}`}>{row.UnitName}</Link></td>
                            <td><Link to={`/town/${row.Town}`}>{row.Town}</Link></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default EmployeeDetails;
