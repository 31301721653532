import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, GeoJSON, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './MapComponent.css';

// Component to manage tooltip visibility based on zoom level
function SetTooltipVisibility({ setShowTooltips, zoomThreshold }) {
  const map = useMapEvents({
    zoomend: () => {
      const currentZoom = map.getZoom();
      console.log('Current Zoom:', currentZoom);  // Log the current zoom level to console
      if (currentZoom >= zoomThreshold) {
        setShowTooltips(true);  // Show tooltips when zoomed in
        console.log('Zoom threshold met');
      } else {
        setShowTooltips(false);  // Hide tooltips when zoomed out
        console.log('Zoom threshold not met');
      }
    }
  });

  // Initial check on component mount
  useEffect(() => {
    const currentZoom = map.getZoom();
    if (currentZoom >= zoomThreshold) {
      setShowTooltips(true);
    } else {
      setShowTooltips(false);
    }
  }, [map, zoomThreshold, setShowTooltips]); // Run only on mount

  return null;
}


const MapComponent = ({ center = [41.5, -72.7], zoom = 9 }) => {
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [showTooltips, setShowTooltips] = useState(false);  // Start with tooltips visible
  const [geoJsonLayer, setGeoJsonLayer] = useState(null);  // Store the GeoJSON layer reference

  useEffect(() => {
    // Fetch the GeoJSON file from the public directory
    fetch('/CT_Municipalities.geojson')
      .then((response) => response.json())
      .then((data) => setGeoJsonData(data))
      .catch((error) => console.error('Error fetching GeoJSON:', error));
  }, []);

  const defaultStyle = {
    color: '#ffffff',  // White border for towns
    weight: 2,         // Slightly thicker border
    fillColor: '#007acc',  // Blue fill
    fillOpacity: 0.7
  };

  const highlightStyle = {
    color: '#ffcc00',  // Yellow border on hover
    weight: 3,
    fillOpacity: 0.7
  };

  const onEachFeature = (feature, layer) => {
    layer.setStyle(defaultStyle);
  
    if (feature.properties && feature.properties.Municipality) {
      layer.on({
        mouseover: (e) => {
          e.target.setStyle(highlightStyle);
        },
        mouseout: (e) => {
          e.target.setStyle(defaultStyle);
        },
      });
    }
  };

  // UseEffect to update tooltips whenever `showTooltips` changes
  useEffect(() => {
    if (geoJsonLayer && geoJsonData) {
      geoJsonLayer.clearLayers(); // Clear existing layers before reapplying
      geoJsonLayer.addData(geoJsonData);

      geoJsonLayer.eachLayer((layer) => {
        const feature = layer.feature;
        if (feature.properties && feature.properties.Municipality) {
          if (showTooltips) {
            layer.bindTooltip(feature.properties.Municipality, {
              permanent: true,
              direction: 'center',
              className: 'town-tooltip',
              offset: [0, 0],
            }).openTooltip(); // Show tooltips when zoom threshold is met
          } else {
            layer.unbindTooltip(); // Remove tooltips if zoom threshold is not met
          }
        }
      });
    }
  }, [showTooltips, geoJsonData, geoJsonLayer]); // Re-run the logic when `showTooltips` or data changes

  return (
    <MapContainer center={center} zoom={zoom} style={{ height: '400px', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {geoJsonData && (
        <GeoJSON 
          data={geoJsonData} 
          onEachFeature={onEachFeature} 
          ref={(layer) => setGeoJsonLayer(layer)}  // Capture the GeoJSON layer reference
        />
      )}
      
      {/* Add zoom event listener and toggle tooltips based on zoom */}
      <SetTooltipVisibility setShowTooltips={setShowTooltips} zoomThreshold={10} />
    </MapContainer>
  );
};

export default MapComponent;
