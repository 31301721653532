import React from "react";
import Nav from "./../navigation/Nav";


class MetricsPage extends React.Component {
  state = {
    bargainingUnitsCount: 0,
    totalActiveJobs: 0,
    totalOptOuts: 0,
    peopleContributors: 0,
    totalDuesPayers: 0,
    totalRecommitsSaves: 0,
    UnassignedUnits: 0,
  };

  componentDidMount() {
    fetch("/api/metrics")
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          bargainingUnitsCount: data.bargainingUnitsCount,
          totalActiveJobs: data.totalActiveJobs,
          totalOptOuts: data.totalOptOuts,
          peopleContributors: data.peopleContributors,
          totalDuesPayers: data.totalDuesPayers,
          totalRecommitsSaves: data.totalRecommitsSaves,
          UnassignedUnits: data.UnassignedUnits,
        });
      })
      .catch((error) => console.error("Error fetching metrics:", error));
  }

  

  render() {
    const {
      bargainingUnitsCount,
      totalActiveJobs,
      totalOptOuts,
      peopleContributors,
      totalDuesPayers,
      totalRecommitsSaves,
      UnassignedUnits,
    } = this.state;
    const metricBoxStyle = {
      width: "20%",
      border: "2px solid #007bff", // Bootstrap primary color
      borderRadius: "100px",
      padding: "20px",
      textAlign: "center",
      transition: "transform 0.3s ease-in-out",
      fontSize: "20px",
      marginTop: "150px",
    };

    return (
      <>
        <Nav />
        <div
          style={{
            fontSize: "60px",
            textAlign: "center",
            fontWeight: "bolder",
            marginTop: "40px",
          }}
        >
          Metrics
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            justifyContent: "center",
            margin: "20px",
          }}
        >
          <div style={{ ...metricBoxStyle }} className="metric-box">
            <h2>Total Bargaining Units</h2>
            <h2>{bargainingUnitsCount}</h2>
          </div>
          <div style={{ ...metricBoxStyle }} className="metric-box">
            <h2>Total Active Jobs</h2>
            <h2>{totalActiveJobs}</h2>
          </div>
          <div style={{ ...metricBoxStyle }} className="metric-box">
            <h2>Total Opt Outs</h2>
            <h2>{totalOptOuts}</h2>
          </div>
          <div style={{ ...metricBoxStyle }} className="metric-box">
            <h2>People Contributors</h2>
            <h2>{peopleContributors}</h2>
          </div>
          <div style={{ ...metricBoxStyle }} className="metric-box">
            <h2>Total Dues Payers</h2>
            <h2>{totalDuesPayers}</h2>
          </div>
          <div style={{ ...metricBoxStyle }} className="metric-box">
            <h2>Total Recommits/Saves</h2>
            <h2>{totalRecommitsSaves}</h2>
          </div>
          <div style={{ ...metricBoxStyle }} className="metric-box">
            <h2>Unassigned Units</h2>
            <h2>{UnassignedUnits}</h2>
          </div>
        </div>
      </>
    );
  }
}

export default MetricsPage;
